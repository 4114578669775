<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>مركز التدريب</h3></div>

    <v-divider></v-divider>
    <v-container>
      <v-row>
        <h4 class="blue--text">
          شركه مياه الشرب والصرف الصحي بأسيوط والوادي الجديد
        </h4>
        <h4>/ المعرض</h4>
        <h4>/ مركز التدريب</h4></v-row
      >
      <v-row justify="center" class="mt-10">
        <v-col
          v-for="(expandImg, i) in ImagesLab"
          :key="i"
          class="pa-1 ma-1"
          xs="8"
          sm="5"
          md="5"
          lg="3"
          cols="8"
        >
          <expandable-image class="Labimg scale-in-center" :src="expandImg"     
                    :lazy-src="loadingLink"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
          
<script>
export default {
  data() {
    return {
           loadingLink:require("@/assets/Images/load.svg"),
      ImagesLab: [
        require("@/assets/Images//47.jpg"),
        require("@/assets/Images/48.jpg"),
        require("@/assets/Images/49.jpg"),
        require("@/assets/Images/50.jpg"),
        require("@/assets/Images/traning_dep/131.jpg"),
        require("@/assets/Images/traning_dep/132.jpg"),
        require("@/assets/Images/traning_dep/133.jpg"),
        require("@/assets/Images/traning_dep/134.jpg"),
        require("@/assets/Images/traning_dep/135.jpg"),
        require("@/assets/Images/traning_dep/136.jpg"),
        require("@/assets/Images/traning_dep/137.jpg"),
        require("@/assets/Images/traning_dep/138.jpg"),
        require("@/assets/Images/traning_dep/139.jpg"),
      ],
    };
  },
};
</script>
<style>
.Labimg {
  box-shadow: gray 3px 3px 3px 3px;
}
</style>